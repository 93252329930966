@use '@angular/material' as mat;
@import '~angular-calendar/css/angular-calendar.css';

@import "~ng-pick-datetime/assets/style/picker.min.css";

@include mat.core();

$candy-app-primary: mat.define-palette(mat.$blue-grey-palette,800);
$candy-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$candy-app-warn: mat.define-palette(mat.$red-palette);

$candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

/* @include mat-core-theme($candy-app-theme);
@include mat-autocomplete-theme($candy-app-theme);
@include mat-button-toggle-theme($candy-app-theme);
@include mat-card-theme($candy-app-theme);
@include mat-checkbox-theme($candy-app-theme);
@include mat-chips-theme($candy-app-theme);
@include mat-datepicker-theme($candy-app-theme);
@include mat-dialog-theme($candy-app-theme);
@include mat-grid-list-theme($candy-app-theme);
@include mat-icon-theme($candy-app-theme);
@include mat-input-theme($candy-app-theme);

@include mat-menu-theme($candy-app-theme);
@include mat-progress-bar-theme($candy-app-theme);
@include mat-progress-spinner-theme($candy-app-theme);
@include mat-radio-theme($candy-app-theme);
@include mat-sidenav-theme($candy-app-theme);
@include mat-slide-toggle-theme($candy-app-theme);
@include mat-slider-theme($candy-app-theme);
@include mat-tabs-theme($candy-app-theme);
@include mat-toolbar-theme($candy-app-theme);
@include mat-tooltip-theme($candy-app-theme);
 */

/* @include mat-button-theme($candy-app-theme);
@include angular-material-theme($candy-app-theme); */
@include mat.menu-theme($candy-app-theme);
@include mat.core-theme($candy-app-theme);
@include mat.input-theme($candy-app-theme);
@include mat.dialog-theme($candy-app-theme);
@include mat.card-theme($candy-app-theme);
@include mat.tooltip-theme($candy-app-theme);
@include mat.checkbox-theme($candy-app-theme);
@include mat.radio-theme($candy-app-theme);
@include mat.list-theme($candy-app-theme);

.grey-theme{
  @include mat.select-theme($candy-app-theme);
  @include mat.datepicker-theme($candy-app-theme);
  @include mat.button-theme($candy-app-theme);
  @include mat.all-component-themes($candy-app-theme);
  .mat-header-color{
    background:mat.get-color-from-palette($candy-app-primary);
  }
  .table-header-color {
    background:mat.get-color-from-palette($candy-app-primary);
  }
  .header {
    background:mat.get-color-from-palette($candy-app-primary) !important;
  }
  .mat-drawer{
    background:mat.get-color-from-palette($candy-app-primary) !important;
  }
 
  }


// Our dark theme

  $dark-primary: mat.define-palette(mat.$indigo-palette);
  $dark-accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);
  
  // The warn palette is optional (defaults to red).
  $dark-warn:  mat.define-palette(mat.$red-palette);
  
  // Create the theme object (a Sass map containing all of the palettes).
  $dark-theme: mat.define-light-theme($dark-primary, $dark-accent, $dark-warn);

  @include mat.menu-theme($dark-theme);
  @include mat.select-theme($dark-theme);
  @include mat.core-theme($dark-theme);
  @include mat.input-theme($dark-theme);
  @include mat.dialog-theme($dark-theme);
  @include mat.card-theme($dark-theme);
  @include mat.tooltip-theme($dark-theme);
  @include mat.checkbox-theme($dark-theme);
  @include mat.radio-theme($dark-theme);
  @include mat.datepicker-theme($dark-theme);
.indigo-theme {
 
  @include mat.button-theme($dark-theme);
  @include mat.all-component-themes($dark-theme);
  
  .mat-header-color{
    background:mat.get-color-from-palette($dark-primary);
  }
  .table-header-color{
    background:mat.get-color-from-palette($dark-primary);
  }

 .header{
    background-color:mat.get-color-from-palette($dark-primary) !important;
  }  
  .mat-drawer{
    background:mat.get-color-from-palette($dark-primary) !important;
  }
}


//Blue Theme
$blue-primary: mat.define-palette(mat.$blue-palette);
$blue-accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$blue-warn:  mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$blue-theme: mat.define-light-theme($blue-primary, $blue-accent, $blue-warn);

@include mat.menu-theme($blue-theme);
@include mat.select-theme($blue-theme);
@include mat.core-theme($blue-theme);
@include mat.input-theme($blue-theme);
@include mat.dialog-theme($blue-theme);
@include mat.card-theme($blue-theme);
@include mat.tooltip-theme($blue-theme);
@include mat.checkbox-theme($blue-theme);
@include mat.radio-theme($blue-theme);
@include mat.datepicker-theme($blue-theme);
.blue-theme {

@include mat.button-theme($blue-theme);
@include mat.all-component-themes($blue-theme);

.mat-header-color{
  background:mat.get-color-from-palette($blue-primary);
}
.table-header-color{
  background:mat.get-color-from-palette($blue-primary);
}
.header {
  background-color:mat.get-color-from-palette($blue-primary) !important;
}
.mat-drawer{
  background:mat.get-color-from-palette($blue-primary) !important;
}
}

//Deep Purple Theme
$deeppurple-primary: mat.define-palette(mat.$deep-purple-palette);
$deeppurple-accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$deeppurple-warn:  mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$deeppurple-theme: mat.define-light-theme($deeppurple-primary, $deeppurple-accent, $deeppurple-warn);

@include mat.menu-theme($deeppurple-theme);
@include mat.core-theme($deeppurple-theme);
@include mat.select-theme($deeppurple-theme);
@include mat.input-theme($deeppurple-theme);
@include mat.dialog-theme($deeppurple-theme);
@include mat.card-theme($deeppurple-theme);
@include mat.tooltip-theme($deeppurple-theme);
@include mat.checkbox-theme($deeppurple-theme);
@include mat.radio-theme($deeppurple-theme);
.deeppurple-theme {

@include mat.button-theme($deeppurple-theme);
@include mat.all-component-themes($deeppurple-theme);

.mat-header-color{
  background:mat.get-color-from-palette($deeppurple-primary);
}
.table-header-color{
  background:mat.get-color-from-palette($deeppurple-primary);
}

.header {
  background-color:mat.get-color-from-palette($deeppurple-primary) !important;
}
.mat-drawer{
  background:mat.get-color-from-palette($deeppurple-primary) !important;
}

}


//Teal Theme
$teal-primary: mat.define-palette(mat.$teal-palette);
$teal-accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$teal-warn:  mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$teal-theme: mat.define-light-theme($teal-primary, $teal-accent, $teal-warn);

@include mat.menu-theme($teal-theme);
@include mat.select-theme($teal-theme);
@include mat.core-theme($teal-theme);
@include mat.input-theme($teal-theme);
@include mat.dialog-theme($teal-theme);
@include mat.card-theme($teal-theme);
@include mat.tooltip-theme($teal-theme);
@include mat.checkbox-theme($teal-theme);
@include mat.radio-theme($teal-theme);
.teal-theme {

@include mat.button-theme($teal-theme);
@include mat.all-component-themes($teal-theme);

.mat-header-color{
  background:mat.get-color-from-palette($teal-primary);
}
.table-header-color{
  background:mat.get-color-from-palette($teal-primary);
}

.header {
  background-color:mat.get-color-from-palette($teal-primary) !important;
}
.mat-drawer{
  background:mat.get-color-from-palette($teal-primary) !important;
}

}


//Pink Theme
$pink-primary: mat.define-palette(mat.$pink-palette,900);
$pink-accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$pink-warn:  mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$pink-theme: mat.define-light-theme($pink-primary, $pink-accent, $pink-warn);

@include mat.menu-theme($pink-theme);
@include mat.select-theme($pink-theme);
@include mat.core-theme($pink-theme);
@include mat.input-theme($pink-theme);
@include mat.dialog-theme($pink-theme);
@include mat.card-theme($pink-theme);
@include mat.tooltip-theme($pink-theme);
@include mat.checkbox-theme($pink-theme);
@include mat.radio-theme($pink-theme);

.pink-theme {

@include mat.button-theme($pink-theme);
@include mat.all-component-themes($pink-theme);

.mat-header-color{
  background:mat.get-color-from-palette($pink-primary);
}
.table-header-color{
  background:mat.get-color-from-palette($pink-primary);
}

.header {
  background-color:mat.get-color-from-palette($pink-primary) !important;
}
.mat-drawer{
  background:mat.get-color-from-palette($pink-primary) !important;
}
}

//Cyan Theme
$cyan-primary: mat.define-palette(mat.$cyan-palette,600);
$cyan-accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$cyan-warn:  mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$cyan-theme: mat.define-light-theme($cyan-primary, $cyan-accent, $cyan-warn);

@include mat.menu-theme($cyan-theme);
@include mat.select-theme($cyan-theme);
@include mat.core-theme($cyan-theme);
@include mat.input-theme($cyan-theme);
@include mat.dialog-theme($cyan-theme);
@include mat.card-theme($cyan-theme);
@include mat.tooltip-theme($cyan-theme);
@include mat.checkbox-theme($cyan-theme);
@include mat.radio-theme($cyan-theme);

.cyan-theme {

@include mat.button-theme($cyan-theme);
@include mat.all-component-themes($cyan-theme);

.mat-header-color{
  background:mat.get-color-from-palette($cyan-primary);
}
.table-header-color{
  background:mat.get-color-from-palette($cyan-primary);
}

.header {
  background-color:mat.get-color-from-palette($cyan-primary) !important;
}
.mat-drawer{
  background:mat.get-color-from-palette($cyan-primary) !important;
}
}


//Brown Theme
$brown-primary: mat.define-palette(mat.$brown-palette,500);
$brown-accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$brown-warn:  mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$brown-theme: mat.define-light-theme($brown-primary, $brown-accent, $brown-warn);

@include mat.menu-theme($brown-theme);
@include mat.select-theme($brown-theme);
@include mat.core-theme($brown-theme);
@include mat.input-theme($brown-theme);
@include mat.dialog-theme($brown-theme);
@include mat.card-theme($brown-theme);
@include mat.tooltip-theme($brown-theme);
@include mat.checkbox-theme($brown-theme);
@include mat.radio-theme($brown-theme);
.brown-theme {

@include mat.button-theme($brown-theme);
@include mat.all-component-themes($brown-theme);

.mat-header-color{
  background:mat.get-color-from-palette($brown-primary);
}
.table-header-color{
  background:mat.get-color-from-palette($brown-primary);
}

.header{
  background-color:mat.get-color-from-palette($brown-primary) !important;
}
.mat-drawer{
  background:mat.get-color-from-palette($brown-primary) !important;
}

}
.mat-datepicker-content {
  width: 246px !important;
}
 .mat-calendar {
 width: 100% !important;
  height: 50% !important;
}
/* 
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0,0,0,.12);
} */

.themeMatMenu{
  height: 16vw !important;
}

.buttonMatMenu{
  height:2vw !important;
  border:2.5px solid whitesmoke !important;
}

html {
  height: 100% !important;
  width: 100% !important;
  min-height: 100vh !important;
}

body {
  margin: 0px !important;
  //  height: 100vh !important;
  width: 100% !important;
  max-width: 100% !important;
  min-height: 100% !important;
  // max-height: 100% !important;  

}

.mat-list-btn {
    border: 1px solid black;
    background-color: whitesmoke;
    color: black;
    padding: 14px 28px;
 
    cursor: pointer;
  } 
  
  .mat-list-btn:hover {
    background-color:#e7e7e7;   
 
  }


.mat-tab-body-wrapper{
  height: 100% !important;
}

.mat-tab-label {
  font-size: 0.9vw !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: lightskyblue !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: dodgerblue !important;;
}

.mat-tab-label-active {
  background-color: red;
  opacity: 1 !important;
}

.mat-tab-label-content {
  margin-bottom: 0.5vw !important;
}

.mat-tab-labels {
  height: 40px !important;
}

.enrollmentPanel .mat-expansion-panel-body{
  padding-left: 0.5vw !important;
  height: 5.5vw !important;
  overflow: auto !important;
  }

mat-expansion-panel-header {
  height: 37px !important
}

.mat-card-header-text {
  margin: 0px 6px !important;
 /*  width: 100% !important; */
}

.mat-menu-item {
  background: 0 0;
  color: rgba(0,0,0,.87);
}


th.mat-header-cell{
  background-color: #ECEFF1;
  color: black;
}

.mat-toolbar-multiple-rows {
  min-height: 25px !important;
}
.cal-week-view .cal-time {
  font-size: 1vw !important;
}
.cal-week-view .cal-day-headers {
  font-size: 1vw;
}

.cal-week-view .complete .cal-event,
.cal-week-view .complete.cal-event  {
    background-color: #b7fd67 !important;
    color:black !important;
  }

  .cal-week-view .open .cal-event,
  .cal-week-view .open.cal-event{
  background-color: #f7f59c  !important;
  color: black !important;
}

.cal-day-selected,
.cal-day-selected:hover {
  background-color:  #0072c6  !important;
}

.cal-week-view .batch .cal-event,
.cal-week-view .batch.cal-event{
background-color: darkblue  !important;
color: whitesmoke !important;
}




.myPanelClass{
margin-left: 3vw !important
}

.cal-disabled
{
  background-color: #d8d7df  !important;
  cursor:none !important;
  pointer-events: none;
}

.mat-form-field-wrapper {
  padding-bottom: 0.6vw;
}

.cal-week-view .cal-time {
  background-color:whitesmoke !important;
  cursor:none !important;
  pointer-events: none;
}

.cal-week-view .cal-time-label-column{
  background-color:whitesmoke !important;
  cursor:none !important;
  pointer-events: none;
}


.cal-week-view .cal-hour-segment .cal-after-hour-start{
  background-color:whitesmoke !important;
  cursor:none !important;
  pointer-events: none;
}
.cal-week-view .canvas .cal-event,
.cal-week-view .canvas.cal-event{
background-color: #f0ac36  !important;
color: black !important;
}

.cal-week-view .timeoff .cal-event,
.cal-week-view .timeoff.cal-event{
background-color: #FF9F9F  !important;
color: black !important;
}

.cal-week-view .current .cal-event,
.cal-week-view .current.cal-event{
  background-color: #f7f59c  !important;
  color: black !important;
  font-weight: bolder;
  animation: blinker 2s linear infinite;
  border: solid 2px #1e90ff;
}
.cal-day-column .cal-hour-segment.cal-hour-start:nth-of-type(odd){
background-color: #e3efff;
}

.cal-day-column .cal-hour-segment.cal-hour-start:nth-of-type(even){
  background-color: rgb(215, 206, 226);
  }
.cal-week-view .cal-time-events .cal-event{
  line-height: 1.5 !important;
}
.cal-week-view .cal-hour-segment {
 border:#b9b8b863 thin dashed!important; 
}

.cal-week-view .cal-hour-segment:hover {
  background-color: #0072c6 !important; //need !important to override in-line style
}

.cal-drag-active:hover{
  background-color: #0072c6 !important; //need !important to override in-line style
}

.cal-week-view .cal-all-day-events .cal-events-row {
  height: 1.5vw !important;
}
.cal-week-view .cal-all-day-events .cal-event{
  height: 1.5vw !important;
  line-height: 1.5 !important;;
}

.cal-week-view{
  margin-right: 1vw;
}

.user-card {
  margin-top: 1vw !important;
  width: 85%;
  margin: auto;
}

.formfield-half {
    width: 45%;
    min-width: 200px;
    max-width: 45%;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-right: 20px;
    margin-left: auto;
}


// font consistency classes
.top-header {
  font-size: 1.3vm;
}

.drop-down {
  font-size: 11px;
}

.header-title {
  font-size: 16px;
}

.tab-title {
  font-size: 12px;
}

.data-field {
  font-size: 0.9vm;
}

.main-header {
  font-size: 1vm;
}
.mat-field-width32 {
  margin-right: 10px;
  width: 32%;
}

.mat-error,
.mat-hint {
  padding: 5px;
}
.mat-drawer-inner-container {
  overflow: hidden !important;
}